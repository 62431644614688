import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useLocation } from 'react-router-dom';

const CreateImageDialog = ({ isOpen, onClose, onImageAdded, projectId }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageNames, setImageNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const handleImageChange = (e) => {
    const files = e.target.files;
    setSelectedImages(files);

    const names = Array.from(files).map((file) => file.name);
    setImageNames(names);
  };

  const handleCreateImage = async () => {
    if (selectedImages.length > 0) {
      setLoading(true);

      try {
        const batchSize = 3; // Number of images per batch
        const totalImages = selectedImages.length;
        let index = 0;

        while (index < totalImages) {
          const formData = new FormData();
          formData.append('project_id', projectId);

          for (let i = index; i < index + batchSize && i < totalImages; i++) {
            formData.append('image_files', selectedImages[i]);
          }

          const response = await fetch('https://dreambox-api.netlify.app/api/addImages', {
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            throw new Error('Failed to upload images');
          }

          index += batchSize;
        }

        // All batches uploaded successfully
        onImageAdded();

        setSelectedImages([]);
        setImageNames([]);
        setLoading(false);
        onClose();
      } catch (error) {
        console.error('Error adding images:', error);
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    setSelectedImages([]);
    setImageNames([]);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Add Images</DialogTitle>
      <DialogContent>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id="image-upload-input"
        />
        <label htmlFor="image-upload-input">
          <IconButton component="span" color="primary">
            <AddPhotoAlternateIcon />
          </IconButton>
          <Typography variant="body1" color="primary">
            Upload Images
          </Typography>
        </label>

        {imageNames.length > 0 && (
          <Box mt={2}>
            <Typography variant="body1" color="textSecondary">
              Selected Images:
            </Typography>
            <ul>
              {imageNames.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} sx={{ color: '#b89043' }}>
          Cancel
        </Button>
        <Button onClick={handleCreateImage} sx={{ color: '#b89043' }}>
          {loading ? 'Adding...' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateImageDialog;
