import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MyProject from './pages/myProject';
import ListImages from './pages/listImages';
import LoginPage from './pages/loginPage';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };


  useEffect(() => {
    const storedIsLoggedIn = sessionStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn === 'true') {
      setIsLoggedIn(true);
    }
  }, []); 

  console.log("this is loggedin", isLoggedIn);
  return (
    <div className="App">
      <Router>
        <Routes>
 
          <Route path="/" element={<LoginPage onLogin={handleLogin} />} />


          <Route
            path="/project"
            element={isLoggedIn ? <MyProject /> : <Navigate to="/" replace />}
          />
          <Route
            path="/listImages/:projectId"
            element={isLoggedIn ? <ListImages /> : <Navigate to="/" replace />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
