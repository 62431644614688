import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Typography,
  Box,
  IconButton,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateProjectDialog from '../components/projectModal';
import logo from '../assets/dreamLogo.png';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleOpenCreateDialog = () => {
    setIsCreateDialogOpen(true);
  };

  const handleImageClick = (projectId, project_name) => {
    navigate(`/listImages/${projectId}`, { state: { projectId: projectId, projectName: project_name } });
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
  };
  const handleCreateProject = (newProject) => {

    setProjects((prevProjects) => [...prevProjects, newProject]);
    handleCloseCreateDialog();
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await axios.delete('https://dreambox-api.netlify.app/api/deleteProject', {
        data: {
          project_id: projectId,
        },
      });
      const response = await axios.get('https://dreambox-api.netlify.app/api/fetchProjects');
      setProjects(response.data);
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  useEffect(() => {
    axios.get('https://dreambox-api.netlify.app/api/fetchProjects')
      .then(response => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#f6f5f1' }}>
        <Toolbar>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" sx={{
            backgroundColor: '#b89043',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#b89043',
            },
          }} onClick={handleOpenCreateDialog}>
            Create Project
          </Button>
        </Toolbar>
      </AppBar>

      <Container
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '64px', // Adjusted margin top to accommodate app bar
        }}
      >
        <Box
          sx={{
            color: '#333',
            textAlign: 'center',
            width: '100%',
          }}
        >
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : error ? (
            <Typography variant="body1">Error fetching data</Typography>
          ) : projects.length > 0 ? (
            <Grid container spacing={4} justifyContent="flex-start">
              {projects.map((project, index) => (
                <Grid item key={project.project_id} xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      onClick={() => handleImageClick(project.project_id, project.project_name)}
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: '200px',
                      }}
                    >
                      <img
                        src={project.thumbnail_url}
                        alt={project.project_name}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                    <Box sx={{ padding: '10px', textAlign: 'center' }}>
                      <Typography variant="subtitle2">{project.project_name}</Typography>
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDeleteProject(project.project_id)}
                        sx={{ marginTop: '10px' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">No projects to show</Typography>
          )}
        </Box>
      </Container>

      <CreateProjectDialog
        isOpen={isCreateDialogOpen}
        onClose={handleCloseCreateDialog}
        onCreate={handleCreateProject}
      />
    </Box>
  );
};

export default Dashboard;
